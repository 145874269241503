@keyframes slideIn {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

.header-visible {
    animation: slideIn 0.3s forwards;
}

.header-hidden {
    animation: slideOut 0.3s forwards;
}