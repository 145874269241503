@font-face {
  font-family: 'BNT';
  src: url("../assets/fonts/BigNoodleTitling/bignoodletitlingrusbydaymarius.ttf") format("truetype"),
  url("../assets/fonts/BigNoodleTitling/bignoodletitlingrusbydaymarius.ttf");
}

@font-face {
  font-family: 'AGP Light';
  src: url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_light.ttf") format("truetype"),
  url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_light.ttf");
}

@font-face {
  font-family: 'AGP Regular';
  src: url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_regular.ttf") format("truetype"),
  url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_regular.ttf");
}

@font-face {
  font-family: 'AGP Medium';
  src: url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_md.ttf") format("truetype"),
  url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_md.ttf");
}

@font-face {
  font-family: 'AGP Bold';
  src: url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_bold.ttf") format("truetype"),
  url("../assets/fonts/Akzidenz-Grotesk-Pro/akzidenzgroteskpro_bold.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Montserrat/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: block;
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Montserrat/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: block;
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Montserrat/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: block;
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Montserrat/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: block;
}

/* montserrat-800italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Montserrat/montserrat-v25-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800italic.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: block;
}

/* montserrat-900italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Montserrat/montserrat-v25-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900italic.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: block;
}