@import "variables";
@import "fonts";
@import "layout";

body {
  background-color: #141543;

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #090252;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00EFA0;
  }
}